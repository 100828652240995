export default {
  "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["35"])},
  "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50"])},
  "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["65"])},
  "SignInHeaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email address and password to log in."])},
  "UpperleatherCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oberleder"])},
  "WP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WP"])},
  "according_model_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wie Modell"])},
  "accountCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
  "additional_upper_particularities_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spezifikationen"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
  "all_leathers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Farben"])},
  "all_outsoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Laufsohlen"])},
  "all_welts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Rahmen"])},
  "amount_app_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
  "amount_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menge"])},
  "antislip_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futterhose"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv!"])},
  "archive_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Bestellung wirklich vorübergehend archivieren?"])},
  "archive_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können archivierte Bestellungen zu einem späteren Zeitpunkt über das Bestellmenü hinzufügen und senden."])},
  "archivedOrdersCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespeichert"])},
  "archived_orders_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivierte Bestellungen"])},
  "as_previous_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Model wie gehabt"])},
  "ball_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ballen Stärke:"])},
  "ball_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ballen Stärke"])},
  "black_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["schwarz"])},
  "blue_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blau"])},
  "boarding_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hohe Rückseite"])},
  "boarding_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschalung"])},
  "boarding_front": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hohe Vorderseite"])},
  "bottoming_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BODENBAU"])},
  "brown_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["braun"])},
  "cancel_orderpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Bestellung wirklich verlassen? Die Eingegebenen Daten werden nicht gespeichert."])},
  "catalogue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogus"])},
  "category_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen!"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
  "closing_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschluss 1"])},
  "closing_three_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschluss 3"])},
  "closing_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschluss 2"])},
  "color_1_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunkel"])},
  "color_2_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anthrazit"])},
  "color_3_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Silber"])},
  "color_4_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bronze"])},
  "color_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
  "confirmCodePasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie hier den (6-stelligen) Bestätigungscode ein, den Sie an Ihrer E-Mail-Adresse erhalten haben. (Aktivieren Sie ggf. das Spam-Kontrollkästchen.)"])},
  "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse bestätigen"])},
  "construction_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufbau"])},
  "construction_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bodenspezifikationen"])},
  "construction_execution_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Machart"])},
  "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praktiker"])},
  "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
  "contrefort_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinterkappe"])},
  "customOrderCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom order"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "decorative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekorativ"])},
  "decostitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziernähte"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "edit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bearbeiten"])},
  "email_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Adresse"])},
  "eva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVA"])},
  "eva_rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVA/Gummi Sohlen"])},
  "execution_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variante"])},
  "extra_length_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klettverschluss extra Länge"])},
  "female_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
  "fillsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Korksohle"])},
  "forefoot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorfuß"])},
  "forgot_password_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
  "forgot_password_header_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen"])},
  "form_soles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formsohlen"])},
  "functional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionell"])},
  "heel_expansion_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absatzverbreiterung"])},
  "heel_polster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschlusspolster"])},
  "heel_specifics_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absatzspezifikationen"])},
  "heel_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferse Stärke"])},
  "heel_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ferse Stärke"])},
  "heelbuffer_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pufferabsatz"])},
  "heelcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absatzfarbe"])},
  "heelheight_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absatzsprengung"])},
  "heelmaincolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absatz Erstfarbe"])},
  "heelpolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschlusspolster"])},
  "heelreinforcement_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinterkappen"])},
  "heelrolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absatz-Rolle"])},
  "heelsecondcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absatz Zweitfarbe"])},
  "heeltype_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absatz-Typ"])},
  "height_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe"])},
  "insole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brandsohle"])},
  "languageSettingsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache ändern"])},
  "large_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Großen Ösen"])},
  "large_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Großen Haken"])},
  "lateral_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lateral"])},
  "leather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leder"])},
  "leave_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellseite verlassen?"])},
  "leave_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Bestellseite wirklich verlassen? Eingegebene Daten werden nicht gespeichert."])},
  "left_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Links"])},
  "leg_girth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beinmaβe"])},
  "lining_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blattfutter"])},
  "lining_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futter"])},
  "liningheel_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quartierfutter:"])},
  "liningshoe_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liningshoe"])},
  "login_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
  "low_shoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halbschuhe"])},
  "low_shoe_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halbschuh"])},
  "mainstitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montagenähte"])},
  "male_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
  "material_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material"])},
  "medial_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medial"])},
  "midsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zwischensohle"])},
  "mm_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mm"])},
  "model_information_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modellinformation:"])},
  "modelname_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modellname:"])},
  "modelnumber_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modellnummer:"])},
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nicht anwendbar"])},
  "no_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "nose_length_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorderkappelänge"])},
  "nose_material_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorderkappen"])},
  "not_signed_in_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht eingeloggt!"])},
  "olie_zuurbestendig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öl- und Sauerbeständig"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öffnen"])},
  "openOrdersCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragssynchronisation erzwingen"])},
  "orderButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bestellen!"])},
  "orderHistoryCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellverlauf"])},
  "orderHistoryNone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["x"])},
  "orderMenuCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Bestellungen"])},
  "order_check_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BESTELLUNG ABSCHICKEN"])},
  "order_check_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Formular ist unvollständig!"])},
  "order_check_header_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Formular ist vollständig!"])},
  "order_check_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p style='display: inline' id='quantity'></p> Artikel wurden noch nicht vollständig eingegeben. Verwenden Sie die folgenden Links, um zu den relevanten Elementen zu blättern."])},
  "order_check_message_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BestelluDrücken Sie die Taste unten, um die Bestellung zu senden. Wenn Sie derzeit keine Internetverbindung haben, wird die Bestellung gespeichert und später versendet."])},
  "order_complete_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Formular ist vollständig!"])},
  "order_complete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACHTUNG: Mit dem Absenden dieses Formulars, lösen Sie eine verbindliche Bestellung aus. Diese können Sie 3 Stunden ändern. Mitteilungen im „Leisten-Paket“ können von uns nicht berücksichtigt werden. Wir fertigen die Schuhe ausschließlich nach den Angaben in der APP. Prüfen Sie daher Ihre Bestellung bitte genau. Wir bitten Sie um Verständnis!"])},
  "order_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BestellInformationen"])},
  "order_fail_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Bestellung kann nicht versendet werden!"])},
  "order_fail_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können die Bestellung mit der Schaltfläche unten links auf dieser Seite speichern und versuchen, sie zu einem späteren Zeitpunkt erneut zu senden."])},
  "order_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellformular"])},
  "order_history_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellverlauf"])},
  "order_id_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patientenidentifikation"])},
  "order_id_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistennummer"])},
  "order_id_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Auftragsnummer:"])},
  "order_id_two_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsnummer:"])},
  "order_success_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bestellung wurde erfolgreich bearbeitet!"])},
  "order_success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn eine Bestellung nicht direkt mit der Datenbank synchronisiert werden kann, wird sie auf Ihrem Gerät gespeichert und gesendet, wenn erneut eine Internetverbindung besteht."])},
  "order_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellformular:"])},
  "organisation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundennummer"])},
  "outsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufsohle"])},
  "password_changed_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort wurde erfolgreich geändert und Sie sind angemeldet."])},
  "password_changed_confirmation_before_signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort wurde erfolgreich geändert. Sie können sich jetzt mit Ihrem neuen Passwort anmelden."])},
  "password_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein!"])},
  "pick_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild Auswählen"])},
  "polster_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polster"])},
  "polster_upper_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quartierpolster"])},
  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["drucken"])},
  "provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitgeliefert"])},
  "quarterspace_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstand der Schnürung"])},
  "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbleibend"])},
  "remarks_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bemerkungen"])},
  "reorder_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nachbestellen"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen"])},
  "right_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechts"])},
  "rolling_app_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrolltechnik"])},
  "rolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrolltechnik"])},
  "rolling_type_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen-Typ"])},
  "rolling_type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ Rolle"])},
  "rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gummi"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Auswahl"])},
  "sent_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absenden"])},
  "shore_value_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shorehärte"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einloggen"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
  "signedin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du bist eingeloggt!"])},
  "signout_intent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie sich abmelden?"])},
  "small_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleine Ösen"])},
  "small_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleine Haken"])},
  "solestiffner_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sohlenversteifung"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "stitching_strength_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahtstärke"])},
  "take_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Bild Machen"])},
  "thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dicke"])},
  "toe_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spitze Stärke:"])},
  "toe_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spitze Stärke"])},
  "toes_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zehen"])},
  "tonguefixation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lasche fixieren"])},
  "tonguepolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laschenpolster"])},
  "toplift_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absatzfleck"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langsohlig"])},
  "tvo_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hohe Hinterkappe"])},
  "type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "upperSectionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCHAFT"])},
  "upper_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schaftspezifikationen"])},
  "upper_height_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schafthöhe (inkl. Bettung)"])},
  "upperleather_1_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oberleder 1"])},
  "upperleather_2_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oberleder 2"])},
  "upperleather_3_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oberleder 3"])},
  "upperleather_4_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oberleder 4"])},
  "upperleather_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oberleder"])},
  "user_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner"])},
  "vamppoint_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derbypunkt"])},
  "vamppoint_caption_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derbypunkt"])},
  "welt_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rahmen"])},
  "welt_number_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nummer"])},
  "yes_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])}
}