export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paminkštinimas kulno dalyje"])},
  "SignInHeaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "__1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 - fazės volelis"])},
  "__10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pado sukietinimas anglimi"])},
  "__11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dvigubas pado sukietinimas anglimi"])},
  "__12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prasiūti išorinį padą"])},
  "__13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prasiūti vidpadžius"])},
  "__14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uždari kabliukai"])},
  "__15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulkšnies kauliukai vidinėje pusėje Ø 100 mm"])},
  "__16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulkšnies kauliukai išorinėje pusėje Ø 100 mm"])},
  "__17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulkšnies kauliukai vid. +išor. pusėje Ø 100 mm"])},
  "__18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kulkšnies kauliukų plote"])},
  "__19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVA"])},
  "__2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsiųsta"])},
  "__20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVA/gumos kombinacija"])},
  "__21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prasiūti padus_Flexible"])},
  "__22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skylutės"])},
  "__23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suklijuotas"])},
  "__24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visos"])},
  "__25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sagtelė"])},
  "__26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pilka"])},
  "__27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["žalia"])},
  "__28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kabliukai"])},
  "__29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyras Pakala"])},
  "__3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priderintas prie pado"])},
  "__30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pakulnis (kulnas)"])},
  "__31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medaus"])},
  "__32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velchro vidinėje pusėje"])},
  "__33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velchro"])},
  "__34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["velkro be kilputės"])},
  "__35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sintetinis"])},
  "__36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oda"])},
  "__37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alyvinė"])},
  "__38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vid.+išor.+"])},
  "__39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paminkštinti virš užkulnio 8 mm"])},
  "__4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["padikauliai"])},
  "__40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvigubi kabliukai"])},
  "__41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uždėti gale"])},
  "__42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["uždėti priekyje"])},
  "__43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natūralus"])},
  "__44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.a."])},
  "__45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standartinis volelis"])},
  "__46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nailoninė medžiaga dedasi ant padų priekio"])},
  "__47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["padai atsparūs tepalui, rūgštims"])},
  "__48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oranžinė"])},
  "__49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polifazinis volelis"])},
  "__5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beige"])},
  "__50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polipropilenas"])},
  "__51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rhenoflex solestiffening"])},
  "__52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rhenoflex"])},
  "__53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["akutės"])},
  "__54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["užtrauktukas"])},
  "__55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["raudona"])},
  "__56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guma"])},
  "__57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kabliukai su žiedeliais"])},
  "__58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakulnio tipas nr. 5"])},
  "__59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakulnio tipas nr. 5 + nr.6"])},
  "__6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atitinka pagrindinės odos spalvą"])},
  "__60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakulnio tipas nr. 6"])},
  "__61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["metalinė"])},
  "__62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standartinis"])},
  "__63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taupe"])},
  "__64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liežuvis"])},
  "__65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["turkio"])},
  "__66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiksacijos aukštis"])},
  "__67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVO Nr:"])},
  "__68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medžiagos"])},
  "__69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipas"])},
  "__7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medinis pakulnis nr.1"])},
  "__70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["įpjova liežuvyje"])},
  "__71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lėtas volelis"])},
  "__72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["greitas volelis"])},
  "__73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["užsegimas su raišteliais"])},
  "__74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frezuoti padai"])},
  "__75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["balta"])},
  "__76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ercoflex"])},
  "__77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tamsiai rud"])},
  "__78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "__8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freelock užsegimo sistema"])},
  "__9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bordo"])},
  "according_model_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagal modelį"])},
  "accountCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "additional_upper_particularities_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skirtingas"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "all_leathers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "all_outsoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "all_welts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "amount_app_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "amount_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiekis, vnt."])},
  "antislip_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neslystantis kulno"])},
  "archive_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "archive_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "archived_orders_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "as_previous_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "ball_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["storis padikauliai"])},
  "ball_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "black_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juodas"])},
  "blue_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mėlyna"])},
  "boarding_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apjungtis"])},
  "bottoming_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padų apačios"])},
  "brown_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rudas"])},
  "cancel_orderpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "category_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medžiagos"])},
  "closing_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsegimas 1"])},
  "closing_three_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsegimas 3"])},
  "closing_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užsegimas 2"])},
  "color_1_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juodas"])},
  "color_2_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šviesiai bronzinė spalva"])},
  "color_3_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikelio spalva"])},
  "color_4_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auksinės"])},
  "color_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalva-"])},
  "confirmCodePasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "construction_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iš medžiagos"])},
  "construction_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padų sistemos gamyba"])},
  "construction_execution_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užtraukimui skirta informacija"])},
  "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "contrefort_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kietas užkulnis"])},
  "customOrderCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "decorative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "decostitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekoratyvinė siūlė"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "edit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "email_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "eva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "eva_rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "execution_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forma"])},
  "extra_length_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["papildomas ilgis+"])},
  "female_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "fillsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["papildomas sluoksnis"])},
  "forgotPasswordHeaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "forgot_password_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "forgot_password_header_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "form_soles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "functional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lytis"])},
  "heel_expansion_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pakulnio forma (išvedimai)"])},
  "heel_specifics_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kulno specifikacija"])},
  "heel_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["storis pakulnis (kulnas)"])},
  "heel_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "heelbuffer_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amortizuojantis pakulnis"])},
  "heelcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "heelheight_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pakulnio aukštis"])},
  "heellining_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["šonelių pamušalas"])},
  "heelmaincolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagrindinė spalva kulne"])},
  "heelpolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minkštas kraštas"])},
  "heelreinforcement_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Užkulnis"])},
  "heelrolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pakulnio volelis"])},
  "heelsecondcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antra spalva kulne"])},
  "heeltype_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakulnio tipas"])},
  "height_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aukštis"])},
  "insole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vidpadis"])},
  "languageSettingsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "large_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["didelės akutės"])},
  "large_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dideli kabliukai"])},
  "lateral_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["išorinė"])},
  "leather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "leave_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "leave_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "left_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kairė:"])},
  "leg_girth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["blauzdos apimtys"])},
  "lining_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jungties pamušalas"])},
  "lining_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pamušalas"])},
  "liningshoe_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bepamušalinis batas"])},
  "login_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "low_shoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "low_shoe_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "mainstitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagrindinės siūlės"])},
  "male_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "material_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medžiagos"])},
  "medial_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vidinė"])},
  "midsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarpupadis"])},
  "model_information_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Papildoma infomarcija"])},
  "modelname_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelis"])},
  "modelnumber_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelio numeris"])},
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "no_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "nose_length_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noselės ilgis"])},
  "nose_material_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noselė"])},
  "not_signed_in_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "olie_zuurbestendig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "openOrdersCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "orderButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "orderHistoryCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "orderMenuCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_header_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_message_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_complete_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_complete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["užsakymo informacija"])},
  "order_fail_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_fail_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_history_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_id_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_id_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kurpalio numeris"])},
  "order_id_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliento vardas"])},
  "order_id_two_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliento vardas"])},
  "order_success_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["užsakymo forma:"])},
  "organisation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įmonė"])},
  "outsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Padai"])},
  "password_changed_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "password_changed_confirmation_before_signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "password_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "pick_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "polster_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paminkštinimas/porolonas"])},
  "polster_upper_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["batviršio paminkštinimas"])},
  "quarterspace_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atstumas tarp šonelių"])},
  "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "remarks_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pastabos"])},
  "reorder_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "right_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dešinė"])},
  "rolling_app_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volelis"])},
  "rolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volelis/uždėtas ant apačios"])},
  "rolling_type_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volelio tipas"])},
  "rolling_type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sent_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shore_value_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signedin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signout_intent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "small_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mažos akutės"])},
  "small_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maži kabliukai"])},
  "solestiffner_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pado sukietinimas"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "stitching_strength_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siūlai"])},
  "take_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["storis"])},
  "toe_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storis ties pirštais"])},
  "toe_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "toes_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pirštai"])},
  "tonguefixation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liežuvio tvirtinimas"])},
  "tonguepolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paminkštintas liežuvis"])},
  "toplift_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pakala"])},
  "tvo_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVO"])},
  "type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tipas"])},
  "upperSectionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "upper_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batviršių gamyba"])},
  "upper_height_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Batviršio aukštis"])},
  "upperleather_1_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oda 1"])},
  "upperleather_2_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oda 2"])},
  "upperleather_3_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oda 3"])},
  "upperleather_4_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oda 4"])},
  "upperleather_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oda"])},
  "user_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "vamppoint_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Derbi taškas"])},
  "vamppoint_caption_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "welt_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rantas"])},
  "welt_number_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "yes_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}