import { createRouter, createWebHistory } from '@ionic/vue-router';

import HomePage from '@/views/HomePage.vue';
import ModelPage from '@/views/ModelPage.vue';
import OsbSetFootDimensions from '@/views/OsbSetFootDimensions.vue';
import OsbFootLastProposals from '@/views/OsbFootLastProposals.vue';
import OsbAlternativeLastProposals from '@/views/OsbAlternativeLastProposals.vue';

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: HomePage
    },
    {
        path: '/osb/foot-dimensions/:category',
        component: OsbSetFootDimensions
    },
    {
        path: '/osb/foot-dimensions/last-proposals/:category',
        component: OsbFootLastProposals
    },
    {
        path: '/osb/alternative-lasts/:category',
        component: OsbAlternativeLastProposals
    },
    {
        path: '/osb/alternative-lasts/last-proposals/:category',
        component: OsbFootLastProposals
    },
    {
        path: '/osa/models/:collectionId',
        component: ModelPage
    },
    {
        path: '/osb/models/:collectionId',
        component: ModelPage,
    },
    {
        path: '/osb/order/:category',
        component: () => import("@/views/OsbOrderPage.vue"),
    },
    {
        path: '/print-form/:id',
        component: () => import("@/views/PrintForm.vue"),
    },
    {
        path: '/admin',
        component: () => import("@/views/AdminHomePage.vue")
    },
    {
        path: '/admin/orders',
        component: () => import("@/views/AdminOrders.vue")
    },
    {
        path: '/admin/users',
        component: () => import("@/views/AdminUserPage.vue")
    },
    {
        path: '/admin/models',
        component: () => import("@/views/AdminModelPage.vue")
    },
    {
        path: '/admin/item/:item/:table',
        component: () => import("@/views/AdminItemPage.vue")
    },
    {
        path: '/admin/osb-models',
        component: () => import("@/views/AdminModelPageOSB.vue")
    },
    {
        path: '/admin/lasts',
        component: () => import("@/views/AdminLasts.vue")
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router