<template>
  <div style="width: 100%;display: flex;">
    <div style="width: 40%;margin-left: 5%">
      <q-select
          v-model="model"
          :name="name"
          ref="inputRef"
          :options="items"
          behavior="menu"
          :label="label"
          transition-duration="100"
          dropdown-icon="fal fa-angle-down"
      />
    </div>
  </div>
</template>

<script>

import {QSelect} from "quasar";
import {defineComponent, ref} from "vue";
export default defineComponent({
  name: "FtrSelect",
  components: {
    QSelect
  },
  props: {
    label: ref(),
    rules: ref(),
    name: ref(),
  },
  setup () {
    const inputRef = ref(null)
    const items = ref([
      {value: "0", label: "Geen Voetbed"},
      {
        value: "1", label: "1 mm"
      },
      {
        value: "2", label: "2 mm"
      },
      {
        value: "3", label: "3 mm"
      },
      {
        value: "4", label: "4 mm"
      },
      {
        value: "5", label: "5 mm"
      },
      {
        value: "6", label: "6 mm"
      },
      {
        value: "7", label: "7 mm"
      },
      {
        value: "8", label: "8 mm"
      },
      {
        value: "9", label: "9 mm"
      },
      {
        value: "10", label: "10 mm"
      }
    ])
    return {
      model: ref('5 mm'),
      inputRef,
      items
    }
  }
})

</script>
<style scoped>
label {
  width: 100%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 10px;
}

</style>