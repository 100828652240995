<template>
  <div style="width: 100%; display: flex; margin-top: 30px">
    <div style="width: 40%; margin-top: 6px; margin-left: 4%">
      <q-input
          input-class="text-right"
          inputmode="numeric"
          autofocus
          :label="label"
          ref="inputRef"
          v-model="model"
          type="text"
          :name="name"
        input-align
        :mask="mask"
        suffix="mm"
        :rules="rules"
        lazy-rules
      />
    </div>
    <div
      style="width: 45%; margin-left: 6%; margin-right: 2%; margin-top: -7px"
    >
      <div
        style="
          position: absolute;
          height: 40px;
          width: calc(45% - 38px);
          border-radius: 5px;
        "
      >
        <img
          alt=""
          src="../assets/img/tape6.png"
          style="
            z-index: 0;
            position: absolute;
            width: 100%;
            height: 56px;
            margin-top: 5px;
            border-radius: 5px;
          "
        />
      </div>
      <div style="width: 100%; z-index: 200">
        <q-field
          style="padding: 0; margin: 0"
          borderless
          bottom-slots
          :model-value="value"
          v-model="value"
        >
          <template v-slot:control>
            <ion-range
              mode="ios"
              v-model="value"
              :name="name2"
              :model-value="value"
              :pin="true"
              :pin-formatter="customFormatter"
              :snaps="true"
              :ticks="false"
              :value="12"
              :min="0"
              :max="20"
              step="1"
              style="
                left: 1px;
                padding: 0;
                margin: 10px 0 0 0;
                position: absolute;
                right: -2px;
              "
            >
            </ion-range>
          </template>
          <template v-slot:hint>
            <div>{{ "overmaat" }}</div>
          </template>
        </q-field>
      </div>
    </div>
  </div>
</template>

<script>
import { IonRange } from "@ionic/vue";

import { QInput, QField, QTooltip, QBtn } from "quasar";

import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "FtrFootLengthInput",
  components: {
    QBtn,
    QTooltip,
    QInput,
    QField,
    IonRange,
  },
  props: {
    label: ref(),
    mask: ref(),
    rules: ref(),
    name: ref(),
    name2: ref(),
  },
  setup() {
    const value = ref(12);
    const model = ref("");
    const rangeLabel = ref("");
    const customFormatter = () => value.value + "mm";
    return {
      value,
      model,
      rangeLabel,
      customFormatter,
    };
  },
});
</script>
<style scoped>
ion-range::part(knob) {
  background-image: url("../assets/img/fingerprintje.png");
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: 50%;
  width: 30px;
  box-shadow: 0 8px 5px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.13),
    0 0 0 1px rgba(0, 0, 0, 0.02);
  height: 30px;
  padding: 0;
}

ion-range::part(bar) {
  opacity: 0;
}

ion-range::part(bar-active) {
  opacity: 0;
}

ion-range::part(pin) {
  transform: translate3d(0px, 0px, 0px) scale(1);
  padding: 8px;
  display: inline-block;
  position: relative;
  top: -30px;
  min-width: 28px;
  transition: unset;
  background: transparent;
  color: gray;
  font-size: 12px;
  text-align: center;
}
label {
  width: 100%;
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 10px;
}
</style>