export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋身泡棉"])},
  "SignInHeaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "__1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["着力点在脚掌心"])},
  "__10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬中底港宝"])},
  "__11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["双层硬中底港宝"])},
  "__12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车穿大底"])},
  "__13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["车穿中底"])},
  "__14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密封鞋勾"])},
  "__15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内侧脚踝 Ø 100 mm"])},
  "__16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外侧脚踝 Ø 100 mm"])},
  "__17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内侧+外侧脚踝 Ø 100 mm"])},
  "__18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内外侧脚踝一片式"])},
  "__19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVA"])},
  "__2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["配送"])},
  "__20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EVA/橡胶组合"])},
  "__21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮脚外翻"])},
  "__22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["洞"])},
  "__23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已过胶"])},
  "__24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["整片（泡棉）"])},
  "__25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋扣"])},
  "__26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["灰"])},
  "__27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绿色"])},
  "__28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["勾勾"])},
  "__29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["男士天皮"])},
  "__3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匹配大底"])},
  "__30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后跟"])},
  "__31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蜜糖"])},
  "__32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋舌内侧魔术勾"])},
  "__33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔术毛勾"])},
  "__34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["毛毛勾勾不要折边"])},
  "__35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合成"])},
  "__36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["皮"])},
  "__37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["丁香红"])},
  "__38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内腰+外腰"])},
  "__39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["底部到顶部8毫米"])},
  "__4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["脚趾骨"])},
  "__40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M型勾勾"])},
  "__41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置后移"])},
  "__42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置前移"])},
  "__43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天然"])},
  "__44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["n.a."])},
  "__45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标准"])},
  "__46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尼龙"])},
  "__47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防油耐酸材料"])},
  "__48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["橙色"])},
  "__49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["着力点圆顺"])},
  "__5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["米白"])},
  "__50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬塑胶港宝"])},
  "__51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬塑胶港宝"])},
  "__52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["硬港宝"])},
  "__53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋眼"])},
  "__54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拉链"])},
  "__55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["红色"])},
  "__56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["橡胶"])},
  "__57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登山扣"])},
  "__58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2号后跟"])},
  "__59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4号后跟"])},
  "__6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与皮料颜色一致"])},
  "__60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3号后跟"])},
  "__61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["铁"])},
  "__62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标准"])},
  "__63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["褐"])},
  "__64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋舌"])},
  "__65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["土耳其绿"])},
  "__66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["港宝的高度"])},
  "__67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVO号码:"])},
  "__68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["材料"])},
  "__69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
  "__7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1号木根"])},
  "__70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋舌切口"])},
  "__71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["着力点在脚掌心偏前"])},
  "__72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["着力点在脚掌心后"])},
  "__73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋带绑紧"])},
  "__74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成型大底"])},
  "__75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白"])},
  "__76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["材料名字 - 软港宝"])},
  "__77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沙石"])},
  "__78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "__8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOA-ATOP"])},
  "__9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["波尔多酒红"])},
  "according_model_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按图片一样"])},
  "accountCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "additional_upper_particularities_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替代品"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "all_leathers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "all_outsoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "all_welts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "amount_app_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "amount_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
  "antislip_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防滑后跟"])},
  "archive_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "archive_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "archived_orders_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "as_previous_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "ball_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["厚度脚趾骨"])},
  "ball_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "black_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑色"])},
  "blue_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["蓝色"])},
  "boarding_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包边条"])},
  "bottoming_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["底部"])},
  "brown_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["褐色"])},
  "cancel_orderpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "category_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["材料"])},
  "closing_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紧固件 1"])},
  "closing_three_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紧固件 3"])},
  "closing_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紧固件 2"])},
  "color_1_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["枪色"])},
  "color_2_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["青古铜"])},
  "color_3_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["银色"])},
  "color_4_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金色"])},
  "color_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["颜色"])},
  "confirmCodePasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "construction_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用某种材料"])},
  "construction_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋底摘要"])},
  "construction_execution_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["做法信息"])},
  "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "contrefort_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后港宝"])},
  "customOrderCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "decorative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "decostitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["装饰车线"])},
  "edit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "email_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "eva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "eva_rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "execution_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实际操作"])},
  "extra_length_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加长鞋头"])},
  "female_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "fillsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["脚床加厚"])},
  "forgotPasswordHeaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "forgot_password_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "forgot_password_header_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "form_soles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "functional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["性別"])},
  "heel_expansion_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后支撑跟"])},
  "heel_specifics_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后跟型号"])},
  "heel_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["厚度后跟"])},
  "heel_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "heelbuffer_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防震跟"])},
  "heelcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "heelheight_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后跟高度"])},
  "heellining_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋身里"])},
  "heelmaincolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主后跟色"])},
  "heelpolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后领口泡棉"])},
  "heelreinforcement_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["补强"])},
  "heelrolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后跟上翘"])},
  "heelsecondcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2号后跟色"])},
  "heeltype_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后跟类型"])},
  "height_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高度"])},
  "insole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中底"])},
  "languageSettingsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "large_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大鞋眼"])},
  "large_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大鞋勾"])},
  "lateral_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外腰"])},
  "leather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "leave_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "leave_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "left_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["左:"])},
  "leg_girth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["围度 （宽度）"])},
  "lining_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋头里"])},
  "lining_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内里"])},
  "liningshoe_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内里鞋"])},
  "login_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "low_shoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "low_shoe_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "mainstitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主车线"])},
  "male_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "material_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["材料"])},
  "medial_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内腰"])},
  "midsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["橡胶加皮的组合中底"])},
  "model_information_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["造型信息"])},
  "modelname_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["造型名称"])},
  "modelnumber_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["造型编号"])},
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "no_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "nose_length_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鼻头长度"])},
  "nose_material_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋头盖"])},
  "not_signed_in_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "olie_zuurbestendig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "openOrdersCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "orderButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "orderHistoryCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "orderMenuCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_header_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_message_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_complete_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_complete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单信息"])},
  "order_fail_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_fail_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_history_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_id_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_id_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["楦头号"])},
  "order_id_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORD"])},
  "order_id_two_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORD"])},
  "order_success_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单:"])},
  "organisation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组织"])},
  "outsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大底"])},
  "password_changed_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "password_changed_confirmation_before_signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "password_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "pick_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "polster_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["泡棉"])},
  "polster_upper_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋面泡棉"])},
  "quarterspace_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["眼片开口"])},
  "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "remarks_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["备注"])},
  "reorder_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "right_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["右:"])},
  "rolling_app_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["着力点"])},
  "rolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加一片EVA"])},
  "rolling_type_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["着力点类型"])},
  "rolling_type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sent_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shore_value_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signedin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signout_intent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "small_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小鞋眼"])},
  "small_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小鞋勾"])},
  "solestiffner_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大底港宝"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "stitching_strength_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["线"])},
  "take_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["厚度"])},
  "toe_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大脚趾骨厚度"])},
  "toe_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "toes_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大脚趾骨"])},
  "tonguefixation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定鞋舌"])},
  "tonguepolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋舌泡棉"])},
  "toplift_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天皮"])},
  "tvo_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVO"])},
  "type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
  "upperSectionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "upper_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋面摘要"])},
  "upper_height_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["鞋面高"])},
  "upperleather_1_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["皮 1"])},
  "upperleather_2_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["皮 2"])},
  "upperleather_3_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["皮 3"])},
  "upperleather_4_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["皮 4:"])},
  "upperleather_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["皮"])},
  "user_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
  "vamppoint_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP点"])},
  "vamppoint_caption_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "welt_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沿条"])},
  "welt_number_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "yes_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}