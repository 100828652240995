export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espuma nos canos"])},
  "SignInHeaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "__1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORMATO 2 FASES"])},
  "__10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PALMILHA DE CARBONO"])},
  "__11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARBONO DUPLO (PALMILHA)"])},
  "__12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COSIDO Á PALMILHA"])},
  "__13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COSIDO"])},
  "__14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["easyrollers"])},
  "__15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TORNOZELO DENTRO 100 MM"])},
  "__16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TORNOZELO FORA 100 MM"])},
  "__17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TORNOZELO DENTRO E FORA 100 MM"])},
  "__18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZONA DOS TORNOZELOS"])},
  "__19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MICRO"])},
  "__2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DO CLIENTE"])},
  "__20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MICRO/BORRACHA"])},
  "__21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRATIK"])},
  "__22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BURACOS"])},
  "__23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COLADO"])},
  "__24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POR TODO"])},
  "__25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIVELA"])},
  "__26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cinzento"])},
  "__27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verde"])},
  "__28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASAS"])},
  "__29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TACÃO COM CAPA DE HOMEM"])},
  "__3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["capa igual a sola"])},
  "__30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOCA DO SALTO"])},
  "__31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mel"])},
  "__32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velcro lado dentro (pala)"])},
  "__33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VELCRO COM PASSADOR"])},
  "__34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VELCRO DIRETO"])},
  "__35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SINTÉTICAS"])},
  "__36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PELE"])},
  "__37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lilás"])},
  "__38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LADO DE DENTRO E FORA"])},
  "__39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meindl (8 mm no contraforte até ao topo)"])},
  "__4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NA PATA"])},
  "__40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ASAS DUPLAS"])},
  "__41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIS PARA TRÁS"])},
  "__42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAIS PARA A FRENTE"])},
  "__43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["natural"])},
  "__44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEM"])},
  "__45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORMATO NORMAL"])},
  "__46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nylon"])},
  "__47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANTI-ÓLEO E ACIDO"])},
  "__48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laranja"])},
  "__49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORMATO POLIFÁSICO (BALOIÇO)"])},
  "__5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beije"])},
  "__50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLIPROPILENO"])},
  "__51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PALMILHA RENOFLEX"])},
  "__52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RENOFLEX"])},
  "__53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ILHÓS"])},
  "__54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FECHO ZIP"])},
  "__55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermelho"])},
  "__56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BORRACHA"])},
  "__57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARGOLA"])},
  "__58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUNHA NORMAL - 2"])},
  "__59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUNHA FRANQUEADA - 4"])},
  "__6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igual a côr principal"])},
  "__60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CUNHA DE RAMPA – 3"])},
  "__61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AÇO"])},
  "__62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NORMAL"])},
  "__63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taupe"])},
  "__64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PALA"])},
  "__65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turquesa"])},
  "__66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALTURA DO REFORÇO"])},
  "__67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVO Nº"])},
  "__68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MATERIAL"])},
  "__69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPO"])},
  "__7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SALTO -1"])},
  "__70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incisão na pala"])},
  "__71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORMATO LENTO"])},
  "__72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORMATO RÁPIDO"])},
  "__73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COM CORDÕES"])},
  "__74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOLA PRÉ FABRICADA"])},
  "__75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branco"])},
  "__76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VINIL"])},
  "__77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Areia"])},
  "__78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "__8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SISTEMA BOA"])},
  "__9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bordô"])},
  "according_model_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMO MODELO"])},
  "accountCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "additional_upper_particularities_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alternativa"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "all_leathers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "all_outsoles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "all_welts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "amount_app_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "amount_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantidade"])},
  "antislip_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TACÃO ANTI-DERRAPANTE"])},
  "archive_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "archive_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "archived_orders_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "as_previous_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "ball_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NA PATA ESPESSURA"])},
  "ball_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "black_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preto"])},
  "blue_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azul"])},
  "boarding_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de bordo"])},
  "bottoming_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MONTAGEM"])},
  "brown_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Castanho"])},
  "cancel_orderpage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "category_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MATERIAL"])},
  "closing_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPO DE FECHO DE ORELHAS 1"])},
  "closing_three_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPO DE FECHO DE ORELHAS 3"])},
  "closing_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPO DE FECHO DE ORELHAS 2"])},
  "color_1_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oxidado"])},
  "color_2_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouro velho"])},
  "color_3_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nikkel"])},
  "color_4_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dourado"])},
  "color_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CÔR"])},
  "confirmCodePasswordMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "confirm_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "construction_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo material"])},
  "construction_details_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de construção"])},
  "construction_execution_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONSTRUÇÃO"])},
  "consultant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "contrefort_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTRAFORTE"])},
  "customOrderCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "decorative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "decostitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRAVADOS DECORATIVOS"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "edit_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "email_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "eva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "eva_rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "execution_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INCLINAÇÃO PARA FORA"])},
  "extra_length_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "female_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "fillsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMADA EXTRA"])},
  "forgotPasswordHeaderMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "forgot_password_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "forgot_password_header_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "form_soles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "functional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sexo"])},
  "heel_expansion_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INCLINAÇÃO NO TACÃO"])},
  "heel_specifics_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESPECIFICAÇÕES TACÃO"])},
  "heel_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BOCA DO SALTO ESPESSURA"])},
  "heel_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "heelbuffer_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TACÃO COM PROTECTOR FLEX"])},
  "heelcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "heelheight_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura do tacão"])},
  "heellining_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORRO NOS CANOS"])},
  "heelmaincolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côr principal"])},
  "heelpolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESPUMA NA GOLA"])},
  "heelreinforcement_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFORÇO"])},
  "heelrolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TACÃO ARREDONDADO"])},
  "heelsecondcolor_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Côr secundária"])},
  "heeltype_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPO TACÃO"])},
  "height_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altura"])},
  "insole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PALMILHA"])},
  "languageSettingsCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "large_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhós grandes"])},
  "large_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asas grandes"])},
  "lateral_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LADO FORA"])},
  "leather": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "leave_order_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "leave_order_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "left_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esquerdo"])},
  "leg_girth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MEDIDAS DA PERNA"])},
  "lining_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORRO NA GÁSPEA"])},
  "lining_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORRO"])},
  "liningshoe_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liningshoe"])},
  "login_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "low_shoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "low_shoe_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "mainstitching_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRAVADOS"])},
  "male_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "material_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MATERIAL"])},
  "medial_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LADO DE DENTRO"])},
  "midsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Midsole"])},
  "model_information_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informação do modelo:"])},
  "modelname_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do modelo:"])},
  "modelnumber_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero (nº) do modelo:"])},
  "na": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "no_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "nose_length_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nose length"])},
  "nose_material_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TESTEIRAS"])},
  "not_signed_in_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "olie_zuurbestendig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "openOrdersCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "orderButtonCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "orderHistoryCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "orderMenuCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_header_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_check_message_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_complete_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_complete_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processo"])},
  "order_fail_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_fail_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_history_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_id_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_id_one_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero (nº) da forma"])},
  "order_id_two_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do cliente"])},
  "order_id_two_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome do cliente"])},
  "order_success_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_success_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "order_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processo:"])},
  "organisation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organização"])},
  "outsole_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SOLADO"])},
  "password_changed_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "password_changed_confirmation_before_signin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "password_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "passwords_not_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "pick_image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "polster_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESPUMA"])},
  "polster_upper_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESPUMA NO CANO"])},
  "quarterspace_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABERTURA NOS CANOS"])},
  "remaining": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "remarks_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcas"])},
  "reorder_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "right_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIREITO    (DTO)"])},
  "rolling_app_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRESSOLA"])},
  "rolling_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAMADA EXTRA NA ENTRESSOLA"])},
  "rolling_type_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPO DE ENTRESSOLA"])},
  "rolling_type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "rubber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "sent_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "shore_value_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signedin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "signout_intent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "small_eyelets_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilhós pequenos"])},
  "small_hooks_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["asas pequenas"])},
  "solestiffner_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REFORÇO NA PALMILHA"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "stitching_strength_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPO DE LINHA"])},
  "take_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESPESSURA"])},
  "toe_thickness_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espessura da biqueira"])},
  "toe_thickness_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "toes_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DEDOS"])},
  "tonguefixation_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FIXAÇÃO NA PALA"])},
  "tonguepolster_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESPUMA NA PALA"])},
  "toplift_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAPA (NO SALTO)"])},
  "tvo_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVO"])},
  "type_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIPO"])},
  "upperSectionCaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "upper_details_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de corte"])},
  "upper_height_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALTURA DO CANO"])},
  "upperleather_1_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PELE 1"])},
  "upperleather_2_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PELE 2"])},
  "upperleather_3_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PELE 3"])},
  "upperleather_4_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PELE 4"])},
  "upperleather_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PELE"])},
  "user_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "vamppoint_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENTRADA"])},
  "vamppoint_caption_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "welt_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vira"])},
  "welt_number_caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "yes_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
}