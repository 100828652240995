<template>
  <div style="width: 100%;display: flex;">
    <div style="width: 45%;margin-top: 6px;margin-left: 4%">
      <q-input
          :label="label"
          inputmode="numeric"
          input-class="text-right"
          label="Voetlengte"
          ref="inputRef"
          v-model="modelValue"
          type="text"
          :name="name"
          :mask="mask"
          suffix="mm"
          :rules="rules"
          lazy-rules
      />
    </div>
  </div>
</template>

<script>

import {QInput} from "quasar";
import {defineComponent, ref} from "vue";

export default defineComponent({
  name: "FtrInput",
  components: {
    QInput
  },
  props: {
    label: ref(),
    mask: ref(),
    model: ref(),
    minCharacters: ref(),
    rules: ref(),
    name: ref(),
  },
  setup() {
    const inputRef = ref(null)
const modelValue = ref(null)
    return {
      modelValue: ref(''),
      inputRef,
    }
  }
})

</script>
<style scoped>
label {
  width: 100%;
}

</style>
